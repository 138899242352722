import { useAction, useSubmission } from '@solidjs/router';
import { ActivityIndicator, Button, Heading, Link, TextLink } from '@troon/ui';
import { createEffect, Match, onMount, Show, Suspense, Switch } from 'solid-js';
import { useTrackEvent } from '@troon/analytics';
import { IconCircleCheck } from '@troon/icons';
import { captureException } from '@sentry/solidstart';
import { gql, mutationAction, TroonCardSubscriptionStatus, useMutation } from '../graphql';
import { useUser } from '../providers/user';
import { dayTimeToDate } from '../modules/date-formatting';
import { Receipt } from '../routes/access/checkout/_components/receipt';
import { SupportButton } from './support';
import type { SubscriptionFragment } from '..//graphql';

type Props = {
	subscriptionId: string;
	productId: string;
	onContinue?: () => void;
	continueText?: string;
};

export default function ConfirmSubscription(props: Props) {
	const trackEvent = useTrackEvent();
	const user = useUser();

	const confirmSubmissionAction = useMutation(confirmSubscriptionMutation);
	const confirmSubscription = useAction(confirmSubmissionAction);
	const confirmation = useSubmission(confirmSubmissionAction);

	createEffect(() => {
		if (confirmation.result?.error) {
			captureException(confirmation.result.error);
		}
	});

	onMount(async () => {
		if (!props.subscriptionId) {
			return;
		}
		const data = new FormData();
		data.set('subscriptionId', props.subscriptionId);
		const res = await confirmSubscription(data);
		if (res.data) {
			trackEvent('checkoutSuccess', { productId: props.productId });
		}
	});

	return (
		<Suspense>
			<Switch>
				<Match when={confirmation.pending || !confirmation.result}>
					<ActivityIndicator class="size-16 text-brand">Confirming your Troon Access</ActivityIndicator>
				</Match>
				<Match when={confirmation.result?.error}>
					{(error) => (
						<>
							<p>
								{error()
									.graphQLErrors.map(({ message }) => message)
									.join('')}
							</p>
							<SupportButton />
						</>
					)}
				</Match>
				<Match when={confirmation.result?.data}>
					{(data) => (
						<Show when={user()?.me}>
							{(me) => (
								<div class="flex flex-col gap-8">
									<div class="flex flex-col items-center gap-6 text-center">
										<IconCircleCheck class="text-8xl text-brand" />
										<Heading as="h1" size="h2">
											Your {data().confirmation.subscriptionName} is now active!
										</Heading>
										<p class="text-lg">We’ve sent a confirmation email to {me().email}.</p>
										<Button
											as={Link}
											href="/"
											onClick={(e) => {
												if (props.onContinue) {
													e.preventDefault();
													props.onContinue();
												}
											}}
										>
											{props.continueText ?? 'Start golfing'}
										</Button>
									</div>
									<div class="flex flex-col gap-4 rounded border border-neutral p-6">
										<h2 class="text-xl font-semibold">Order summary</h2>
										<p>
											<Show when={data().confirmation.endDayTime}>
												{(endDayTime) => (
													<>Your membership will renew automatically on {formatDay(dayTimeToDate(endDayTime()))}. </>
												)}
											</Show>
											You can manage your membership through your{' '}
											<TextLink href="/account/access">account settings</TextLink>.
										</p>
										<Receipt subscription={data().confirmation as unknown as SubscriptionFragment} />
									</div>
								</div>
							)}
						</Show>
					)}
				</Match>
			</Switch>
		</Suspense>
	);
}

const confirmSubscription = gql(`mutation confirmTroonCardSubscription($subscriptionId: String!) {
  confirmation: confirmTroonCardSubscriptionPayment(troonCardSubscriptionId: $subscriptionId) {
    id
    status
    subscriptionName
    startDayTime {
      ...DayTime
    }
    endDayTime {
      ...DayTime
    }
    totalAmount {
      ...Currency
    }
    discountAmount {
      ...Currency
    }
    taxAmount {
      ...Currency
    }
    subtotalAmount {
      ...Currency
    }
  }
}`);

const confirmSubscriptionMutation = mutationAction(confirmSubscription, {
	retry: {
		retryIf: (res) => {
			return res.data?.confirmation.status === TroonCardSubscriptionStatus.Active;
		},
	},
	revalidates: undefined,
});

const formatDay = new Intl.DateTimeFormat('en-us', { month: 'numeric', day: 'numeric', year: 'numeric' }).format;
